export default function HomeHero() {
	return (
		<div className="hero-content">
			<div className="hero-content-head">
				<span className="badge">100% Premium Designs Services</span>
				<h1 className="title">
					Premium HTML, React & WordPress Themes & Templates
				</h1>
				<p className="desc">
					We sell themes and templates on ThemeForest and also handle custom
					projects.
				</p>
				<p className="desc">
					Open for collaboration and freelancing! Write to{" "}
					<a href="mailto:glorythemes.com">glorythemes@gmail.com</a>
				</p>
				<div className="link-block">
					<a
						target="_blank"
						rel="noreferrer"
						href="https://themeforest.net/user/glorythemes/portfolio"
						className="btn outline-draw-bottom"
					>
						View Our Portfolio
					</a>
				</div>
			</div>
			<div className="hero-content-body"></div>
		</div>
	);
}
