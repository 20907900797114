import "./assets/scss/App.scss";

import HomeHero from "./components/HomeHero";
import Header from "./components/Header";
import WorksSlider from "./components/WorksCard";

function App() {
	return (
		<div className="App">
			<div className="app-inner">
				<Header />
				<div className="content">
					<section>
						<div className="container">
							<HomeHero />
						</div>
						<div className="container-fluid">
							<WorksSlider />
						</div>
					</section>
				</div>
			</div>
		</div>
	);
}

export default App;
