export const themeForestProjectsData = [
	{
		title: "Universh - Material MultiPurpose HTML Template",
		thumb: "img/portfolio/universh-thumbnail.jpg",
		link: "https://themeforest.net/item/universh-material-education-events-news-learning-centre-kid-school-multipurpose-html-template/15403789",
		sales: "300+",
	},
	{
		title: "Agenchy - Modern Multipurpose HTML5 Template",
		thumb: "img/portfolio/agenchy-thumbnail.jpg",
		link: "https://themeforest.net/item/agenchy-modern-multipurpose-html5-template/17652489",
		sales: "100+",
	},
	{
		title: "Finance Business HTML Responsive Template | Tradingblock",
		thumb: "img/portfolio/tradingblock-thumbnail.jpg",
		link: "https://themeforest.net/item/finance-business-html-responsive-template-tradingblock/18953353",
		sales: "50+",
	},
	{
		title:
			"Stoneworks - A Professional HTML Template for Construction & Architect Business",
		thumb: "img/portfolio/stoneworks-thumbnail.jpg",
		link: "https://themeforest.net/item/stoneworks-a-professional-html-template-for-construction-architect-building-business/20091447",
		sales: "100+",
	},
	{
		title:
			"Organature | A Vibrant HTML Template for Organic Food, Store & Eco Products",
		thumb: "img/portfolio/organature-thumbnail.jpg",
		link: "https://themeforest.net/item/organature-a-vibrant-html-template-for-organic-food-store-eco-products/20191431",
		sales: "10",
	},
];
