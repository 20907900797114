import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Card from "./Card";
import { themeForestProjectsData } from "../utils/content";

function WorksSlider() {
	const [data, setData] = useState(null);
	const settings = {
		dots: false,
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: true,
		speed: 2500,
		autoplaySpeed: 3500,
		cssEase: "linear",
		arrows: false,
		responsive: [
			{
				breakpoint: 1025,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 412,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	useEffect(() => {
		setData(themeForestProjectsData);
	}, [data]);

	return (
		<div className="slider-container">
			{data && (
				<Slider {...settings}>
					{data.length > 0 &&
						data.map((item, index) => (
							<Card
								key={index}
								title={item.title}
								img={item.thumb}
								link={item.link}
								sales={item.sales}
							/>
						))}
				</Slider>
			)}
		</div>
	);
}

export default WorksSlider;
