import sitelogo from "../assets/img/logo-primary.svg";

export default function Header() {
	return (
		<header>
			<div className="container">
				<div className="nav-bar">
					<div className="logo">
						<a href="https://glory-themes.com">
							<img src={sitelogo} alt="Glorythemes" />
						</a>
					</div>
					{/* <ul className="nav nav-items ">
						<li>
							<a href="#works">Portfolio</a>
						</li>
						<li>
							<a href="#contact">Contact</a>
						</li>
					</ul> */}
					<div className="cta">
						<a
							href="mailto:glorythemes@gmail.com"
							className="btn btn-primary-outline"
						>
							Let's Talk <span className="icon">👋</span>
						</a>
					</div>
				</div>
			</div>
		</header>
	);
}
