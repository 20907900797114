export default function Card({ img, title, link, sales }) {
	return (
		<div className="card">
			<div className="card-inner">
				<div className="card-head">
					<a href={link} target="_blank" rel="noreferrer">
						<img
							src={require(`../assets/${img}`)}
							alt="Universh Universh - Material Education, Events, News, Learning Centre & Kid School MultiPurpose HTML Template"
						/>
					</a>
					<p className="highlighter">{sales} Sales</p>
				</div>
				<div className="card-body">
					<h2 className="title">
						<a href={link} target="_blank" rel="noreferrer">
							{title}
						</a>
					</h2>
				</div>
			</div>
		</div>
	);
}
